<template>
  <div>
    <a-card title="生产收款单创建">
      <a-spin :spinning="loading">
        <a-form-model
          ref="form"
          :model="form"
          :rules="rules"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-row>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="number" label="收款编号">
                <a-input v-model="form.number" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="client" label="客户">
                <a-input
                  :value="client.name"
                  disabled="true"
                  style="font-weight: bold"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="handle_time" label="处理日期">
                <a-date-picker
                  v-model="form.handle_time"
                  valueFormat="YYYY-MM-DD"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>

            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="remark" label="备注">
                <a-input v-model="form.remark" allowClear />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>

        <a-divider orientation="left">未付款订单</a-divider>
        <div>
          <div style="margin-top: 16px">
            <a-table
              rowKey="id"
              size="middle"
              :columns="columns"
              :loading="tb_loading"
              :data-source="productsData"
              :pagination="false"
              :row-selection="{
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectChange,
              }"
              :scroll="{ y: '400px' }"
            >
            </a-table>
          </div>
        </div>
        <a-divider orientation="left">账单信息</a-divider>
        <div>
          <a-row gutter="16">
            <!-- <a-col :span="4">
              <a-form-model-item
                prop="discount"
                label="整单折扣"
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
              >
                <a-input-number v-model="form.discount" style="width: 100%" />
              </a-form-model-item>
            </a-col> -->

            <a-col :span="4">
              <a-form-model-item
                prop="other_amount"
                label="其他费用"
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
              >
                <a-input-number
                  v-model="form.other_amount"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="4">
              <a-form-model-item
                label="总计金额(元)"
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
              >
                <a-input-number
                  :value="OrderTotalAmount"
                  :disabled="true"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="4">
              <a-form-model-item
                label="实收金额(元)"
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
              >
                <a-input-number
                  v-model="collection_amount"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </div>

        <a-divider orientation="left">上传付款截图</a-divider>
        <div>
          <a-col :span="24" :md="24">
            <a-upload
              action="/api/sc_payment_images/"
              list-type="picture-card"
              :headers="{
                'X-CSRFToken': Cookies.get('csrftoken'),
                Authorization: `Bearer ${Cookies.get('access')}`,
              }"
              :file-list="form.image_items"
              @preview="handlePreview"
              @change="handleChange"
              :before-upload="beforeUpload"
              name="file"
            >
              <div>
                <a-icon type="plus" />
                <div class="ant-upload-text"></div>
              </div>
            </a-upload>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              @cancel="handleCancel"
            >
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-col>
        </div>
      </a-spin>

      <div style="margin-top: 32px">
        <a-popconfirm title="确定创建吗?" @confirm="create">
          <a-button type="primary" :loading="loading" :disabled="button_dis"
            >批量付款</a-button
          >
        </a-popconfirm>
      </div>
    </a-card>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import moment from "moment";
import { scPaymentsCreate, getScPaymentOrderNumber } from "@/api/finance";
import { productionPaymentList } from "@/api/system";
import { clientsOption, accountsOption } from "@/api/option";
import NP from "number-precision";

export default {
  data() {
    return {
      Cookies,
      description: "新增",
      client: "",
      selectedRowKeys: [],
      collection_amount: "",
      button_dis: false,
      tb_loading: false,
      loading: false,
      model: {},
      form: {},

      // 图片相关
      fileList: [],
      previewVisible: false,
      previewImage: "",
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Authorization: `Bearer ${Cookies.get("access")}`,
      },

      rules: {
        number: [
          { required: true, message: "请输入编号", trigger: "change" },
          { max: 32, message: "超出最大长度 (32)", trigger: "change" },
        ],
        handle_time: [
          { required: true, message: "请选择处理日期", trigger: "change" },
        ],
        other_amount: [
          {
            pattern: new RegExp(/^\d{0,14}(?:\.\d{0,2})?$/),
            message: "其他费用格式不正确",
            trigger: "change",
          },
        ],
      },
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          width: 45,
          customRender: (value, item, index) => {
            return item.isTotal ? "合计" : index + 1;
          },
        },
        {
          title: "编号",
          dataIndex: "number",
          key: "number",
        },
        {
          title: "下单用户",
          dataIndex: "bm_user_name",
          key: "bm_user_name",
          // width:'60px'
        },
        {
          title: "状态",
          dataIndex: "statues",
          key: "statues",
        },
        {
          title: "产品名称",
          dataIndex: "product_name",
          key: "product_name",
        },
        {
          title: "单价",
          dataIndex: "sale_price",
          key: "sale_price",
        },
        {
          title: "订购数量",
          dataIndex: "quantity",
          key: "quantity",
        },
        {
          title: "实际发货",
          dataIndex: "use_quantity",
          key: "use_quantity",
          customRender: (text, record) => {
            const isMismatch = record.quantity !== record.use_quantity;
            const cellClass = isMismatch ? "highlight-cell" : "";
            const content = record.isTotal ? record.totalQuantity : text;
            return {
              children: <div class={cellClass}>{content}</div>,
              attrs: {},
            };
          },
        },
        {
          title: "运费",
          dataIndex: "express_cost",
          key: "express_cost",
          customRender: (text, record) => {
            if (text == 0) {
              return null;
            } else {
              return text;
            }
          },
        },
        {
          title: "包装费",
          dataIndex: "package_cost",
          key: "package_cost",
          customRender: (text, record) => {
            if (text == 0) {
              return null;
            } else {
              return text;
            }
          },
        },
        {
          title: "其他费用",
          dataIndex: "other_amount",
          key: "other_amount",
          customRender: (text, record) => {
            if (text == 0) {
              return null;
            } else {
              return text;
            }
          },
        },
        {
          title: "产品金额",
          dataIndex: "total_amount",
          key: "total_amount",
          customRender: (value, item) => {
            if (item.isTotal) return item.totalAmount;
            return item.total_amount;
          },
        },
        {
          title: "总金额",
          dataIndex: "order_amount",
          key: "order_amount",
          customRender: (value, item) => {
            if (item.isTotal) return item.totalOrder;
            return item.order_amount;
          },
        },
        {
          title: "更新时间",
          dataIndex: "update_time",
          key: "update_time",
          // width:'110px'
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          key: "create_time",
        },
        {
          title: "用户备注",
          dataIndex: "remark",
          key: "remark",
        },
      ],
      productOrderItems: [],
    };
  },
  computed: {
    OrderTotalAmount() {
      let totalAmount = 0;
      let totalExpress = 0;
      let totalPackage = 0;
      let totalOther = 0;
      for (let item of this.productOrderItemsSelect) {
        let amount = NP.times(item.use_quantity, item.sale_price);
        totalAmount = NP.plus(totalAmount, amount);
        totalExpress = NP.plus(totalExpress, item.express_cost || 0);
        totalPackage = NP.plus(totalPackage, item.package_cost || 0);
        totalOther = NP.plus(totalOther, item.other_amount || 0);
      }
      // totalAmount = NP.times(totalAmount, this.form.discount || 100, 0.01);
      totalAmount = NP.plus(totalAmount, this.form.other_amount || 0);
      totalAmount = NP.plus(
        totalAmount,
        totalExpress,
        totalPackage,
        totalOther
      );
      return totalAmount;
    },
    productOrderItemsSelect() {
      return this.productOrderItems.filter((item) =>
        this.selectedRowKeys.includes(item.id)
      );
    },

    productsData() {
      // 统计合计
      // return []
      let totalQuantity = 0,
      totalOrder=0,
        totalAmount = 0,
        totalExpress = 0,
        totalPackage = 0,
        totalOther = 0;
      for (let item of this.productOrderItemsSelect) {
        const salePrice = item.sale_price || 0;

        totalQuantity = NP.plus(totalQuantity, item.use_quantity);
        let amount = NP.times(item.use_quantity, salePrice);
        totalAmount = NP.plus(totalAmount, amount);

        totalExpress = NP.plus(totalExpress, item.express_cost || 0);
        totalPackage = NP.plus(totalPackage, item.package_cost || 0);
        totalOther = NP.plus(totalOther, item.other_amount || 0);
      }
      // 确保折扣不为null且为数字
      // const discount = Number(this.form.discount) || 0;
      // const discountedTotal = NP.times(totalAmount, discount * 0.01);
      totalOrder = NP.plus(totalExpress, totalPackage,totalOther,totalAmount);
      return [
        ...this.productOrderItems,
        {
          id: "-1",
          isTotal: true,
          name: "",
          totalQuantity: totalQuantity,
          totalAmount,
          totalOrder,
        },
      ];
    },
  },
  methods: {
    moment,
    initData() {
      var client_id = this.$route.query.clientId;
      this.resetForm();
      accountsOption({ page_size: 999999, is_active: true }).then((data) => {
        this.accountsItems = data.results;
      });
      clientsOption({ page_size: 999999, is_active: true }).then((data) => {
        this.client = data.results.find((item) => item.id == client_id);
        if (this.client == undefined) {
          this.$message.error("该客户不存在");
        } else {
          console.log(this.client);
          this.get_product_list();
        }
      });
    },
    get_product_list() {
      this.tb_loading = true;
      productionPaymentList({
        page_size: 999999,
        statues: "Shipped",
        client: this.client.id,
        is_paid: false,
      })
        .then((data) => {
          data.results.map((item) => {
            if (item.shipment_quantity == null) {
              item.use_quantity = item.shipment_quantity;
            } else {
              item.use_quantity = item.quantity;
            }
          });
          this.productOrderItems = data.results;
          // console.log(data.results);
          data.results.map((item) => {
            if (item.sale_price == 0) {
              this.$message.error(`${item.number}订单的售价为0,请检查后操作`);
              this.button_dis = true;
            }
            if (item.shipment_quantity != null) {
              item.use_quantity = item.shipment_quantity;
            } else {
              item.use_quantity = item.quantity;
            }
          });
          this.productOrderItems = data.results;
        })
        .finally(() => {
          this.tb_loading = false;
        });
    },

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },

    create() {
      const validOrderId = this.selectedRowKeys.filter((key) => key !== "-1");
      if (validOrderId.length === 0) {
        this.$message.error("请至少选择一个订单进行付款");
        return;
      }
      let payment_images = this.form.image_items.map((item) => {
        return item.response.id;
      });

      if(payment_images.length==0){
        this.$message.error("请提供付款凭证");
        return
      }
      if(this.collection_amount<=0){
        this.$message.error("付款金额为空");
        return
      }
      // if (this.collection_amount != this.OrderTotalAmount) {
      //   this.$message.error("付款金额与订单金额不一致");
      //   return;
      // }


      if (this.form.discount > 100) {
        this.$message.error("折扣不能大于100");
        return;
      }

      this.loading = true;
      let formData = {
        ...this.form,
        client: this.client.id,
        total_received_amount: this.collection_amount,
        total_arrears_amount:this.OrderTotalAmount,
        payment_images,
        payment_orders: validOrderId,
      };
      console.log("formData", formData);
      scPaymentsCreate(formData)
        .then((data) => {
          this.$message.success("创建成功");
          this.$router.push({
            path: "/finance/payment_orders_view",
            query: { clientId: this.client.id },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async handlePreview(file) {
      console.log(file);
      if (!file.url && !file.preview) {
        file.preview = await this.$functions.getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },

    handleChange({ fileList }) {
      console.log("fileList", fileList);
      // 过滤出上传完成的文件
      const completedFiles = fileList.filter((file) => file.status === "done");

      // 如果需要，处理这些文件的response数据
      completedFiles.forEach((file) => {
        if (file.response) {
          console.log("Upload success response:", file.response);
        }
      });

      this.$set(this.form, "image_items", fileList);
    },

    handleCancel() {
      this.previewVisible = false;
    },

    resetForm() {
      this.form = { image_items: [], other_amount: 0 };
      // this.form = {other_amount: 0 };
      getScPaymentOrderNumber().then((data) => {
        this.form = { ...this.form, number: data.number, discount: 100 };
      });
    },
  },
  mounted() {
    console.log("hallo");
    this.initData();
  },
};
</script>

<style scoped>
.highlight-cell {
  background-color: yellow; /* 或者使用更具体的颜色代码，如 #ffff00 */
}
</style>